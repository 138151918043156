<template>
  <div class="">
    <BCard class="mt-1">
      <BRow class="justify-content-between align-items-center mb-[28px]">
        <BCol lg="6">
          <div
            class="text-black text-2xl font-semibold d-flex"
            style="align-items: center"
          >
            <img
              src="https://storage.googleapis.com/komerce/assets/icons/back.svg"
              alt=""
              srcset=""
              class="mr-1"
              style="cursor: pointer;"
              @click="handleBack"
            >
            <strong class="text-black text-xl">Detail Pendamping UMKM</strong>
          </div>
        </BCol>
      </BRow>
      <BRow class="lg:p-2">
        <BCol lg="6">
          <BRow>
            <BCol lg="2">
              <b-avatar
                :src="dataDetail.photo"
                size="4rem"
              />
            </BCol>
            <BCol lg="10">
              <div class="detail_pumkm">
                <div class="text-black text-xl font-semibold mb-[10px]">
                  <span class="d-flex">
                    {{ dataDetail.full_name|| '-' }}
                    <img
                      v-if="dataDetail.id_pumkm !== 0"
                      src="https://storage.googleapis.com/komerce/assets/svg/Badge_PUMKM.svg"
                      alt="badge pumkm"
                      width="16px"
                      class="ml-[5px]"
                    >
                    <img
                      v-if="dataDetail.id_affiliator !== 0"
                      src="https://storage.googleapis.com/komerce/assets/illustration/badge-affiliate.svg"
                      alt="badge pumkm"
                      width="16px"
                      class="ml-[5px]"
                    >
                  </span>
                </div>
                <div class="text-[#828282] text-lg mb-[24px]">
                  {{ dataDetail.email|| '-' }}
                </div>
                <div class="text-[#828282] text-lg d-flex gap-[10px] mb-[5px]">
                  <span>Alamat</span>
                  <span>:</span>
                  <span class="text-[#333333]">{{ dataDetail.city|| '-' }}</span>
                </div>
                <div class="text-[#828282] text-lg d-flex gap-[10px] mb-[5px]">
                  <span>Whatsapp</span>
                  <span>:</span>
                  <b-button
                    size="sm"
                    class="p-0 d-flex align-items-center"
                    variant="light"
                    style="background-color: transparent; border: none"
                    @click="redirectToWa(dataDetail.phone_no)"
                  >
                    <img
                      v-if="dataDetail.phone_no"
                      src="https://storage.googleapis.com/komerce/assets/icons/whatsapp.svg"
                      class="max-w-[25px] me-2"
                    >
                    <span class="text-[#333333] text-lg">{{ dataDetail.phone_no || '-' }}</span>
                  </b-button>
                </div>
                <div class="text-[#828282] text-lg d-flex gap-[10px] mb-[5px]">
                  <span>Sosial Media</span>
                  <span>:</span>
                  <div class="d-flex align-items-center">
                    <b-button
                      v-if="dataDetail.fb !== ''"
                      :href="dataDetail.fb"
                      target="_blank"
                      size="sm"
                      class="p-0"
                      variant="light"
                      style="background-color: transparent; border: none"
                    >
                      <img
                        src="https://storage.googleapis.com/komerce/assets/icons/Facebook-True.svg"
                        class="max-w-[25px]"
                      >
                    </b-button>
                    <b-button
                      v-if="dataDetail.ttk !== ''"
                      size="sm"
                      class="p-0"
                      variant="light"
                      style="background-color: transparent; border: none"
                      :href="dataDetail.ttk"
                      target="_blank"
                    >
                      <img
                        src="https://storage.googleapis.com/komerce/assets/icons/Tiktok-True.svg"
                        class="max-w-[25px]"
                      >
                    </b-button>
                    <b-button
                      v-if="dataDetail.ig !== ''"
                      size="sm"
                      class="p-0"
                      variant="light"
                      style="background-color: transparent; border: none"
                      :href="dataDetail.ig"
                      target="_blank"
                    >
                      <img
                        src="https://storage.googleapis.com/komerce/assets/icons/Instagram-True.svg"
                        class="max-w-[25px]"
                      >
                    </b-button>
                    <b-button
                      v-if="dataDetail.tw !== ''"
                      size="sm"
                      class="p-0"
                      variant="light"
                      style="background-color: transparent; border: none"
                      :href="dataDetail.tw"
                      target="_blank"
                    >
                      <img
                        src="https://storage.googleapis.com/komerce/assets/icons/Tweeter-True.svg"
                        class="max-w-[25px]"
                      >
                    </b-button>
                    <b-button
                      v-if="dataDetail.tg !== ''"
                      size="sm"
                      class="p-0"
                      variant="light"
                      style="background-color: transparent; border: none"
                      :href="dataDetail.tg"
                      target="_blank"
                    >
                      <img
                        src="https://storage.googleapis.com/komerce/assets/icons/Telegram-True.svg"
                        class="max-w-[25px]"
                      >
                    </b-button>
                  </div>
                </div>
              </div>
            </BCol>
          </BRow>
        </BCol>
        <BCol lg="6">
          <div class="detail_pumkm">
            <div class="text-[#333333] text-lg mb-[5px]">
              <span>Link</span> <br>
              <BInputGroup class="mb-[24px]">
                <template #append>
                  <b-input-group-text
                    style="border-left: 0; cursor: pointer;"
                    @click="copyLink"
                  >
                    <span class="text-[#08A0F7]">Salin</span>
                  </b-input-group-text>
                </template>
                <BFormInput
                  id="input-small"
                  v-model="dataDetail.link_pumkm"
                  style="border-right: 0;background: transparent;color: #08A0F7;"
                  class="form-control-link"
                  disabled
                />
              </BInputGroup>
            </div>
            <div class="text-[#333333] text-lg mb-[5px]">
              <span>Keahlian</span> <br>
              <b-form-textarea
                id="textarea"
                v-model="dataDetail.skill"
                :value="dataDetail.skill"
                no-resize
                disabled
              />
            </div>
          </div>
        </BCol>
      </BRow>
    </BCard>
    <BCard>
      <BOverlay
        id="infinite-list"
        variant="light"
        class="overflow-auto"
        :show="loading"
        spinner-variant="primary"
        blur="0"
        opacity=".5"
        rounded="sm"
      >
        <BTable
          id="table-pumkm"
          responsive
          show-empty
          empty-text="Tidak ada data untuk ditampilkan."
          :fields="fields"
          :items="itemsTable"
        >
          <template #cell(no)="data">
            <div style="min-width: 10px !important">
              {{ data.index + 1 }}
            </div>
          </template>

          <template #cell(title)="data">
            <div
              class="d-flex items-center gap-[10px]"
              style="min-width: 20px !important; "
            >
              <div><b-img
                :src="data.item.poster_url"
                fluid
                style="width: 120px; height: 74px; object-fit: cover;"
              /></div>
              <div
                style=" max-width: 200px !important; display: grid;"
              >
                <span class="font-semibold">{{ data.item.title }} :</span>
                <span>
                  {{ truncateText(data.item.subtitle, 30) }}
                </span>
              </div>
            </div>
          </template>

          <template #cell(event_date)="data">
            <div style="min-width: 20px !important">
              {{ DAY_MONTH_YEAR(data.item.event_date) }}
            </div>
          </template>

          <template #cell(max_attendance)="data">
            <div style="min-width: 20px !important">
              {{ data.item.attendance }} / {{ data.item.max_attendance }}
            </div>
          </template>
        </BTable>
      </BOverlay>
    </BCard>
  </div>
</template>

<script>
import { alertError, alertSuccess } from '@toast'
import { newAxiosIns } from '@/libs/axios'
import { DAY_MONTH_YEAR } from '@/libs/filterDate'
import { configColumnsDetail } from '../config'

export default {
  name: 'DetailPendampingUmkm',
  data() {
    return {
      alertError,
      alertSuccess,
      dataDetail: {},
      fields: configColumnsDetail,
      itemsTable: [],
      loading: true,
      DAY_MONTH_YEAR,
    }
  },
  mounted() {
    const data = this.$route.params.detail
    this.dataDetail = data
    if (data === undefined) {
      this.$router.push('/pendamping-umkm')
    }
    this.getDataPerformens()
  },
  methods: {
    handleBack() {
      this.$router.push('/pendamping-umkm')
    },
    redirectToWa(dataPhone) {
      window.open(`https://wa.me/62${dataPhone.substring(1)}`, '_blank')
    },
    copyLink() {
      const linkInput = this.dataDetail.link_pumkm
      navigator.clipboard.writeText(linkInput)
        .then(() => {
          const text = 'Link PUMKM Berhasil di Salin'
          this.alertSuccess(text)
        })
    },
    async getDataPerformens() {
      const url = `/komclass/api/v1/event/by-performers?pumkm_id=${this.dataDetail.id_pumkm}`
      await newAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          this.itemsTable = data.map(i => ({
            ...i.event_detail,
            position: i.position,
          }))
        })
    },
    truncateText(text, maxLength) {
      if (text.length <= maxLength) {
        return text
      }
      return `${text.slice(0, maxLength)}...`
    },
  },
}
</script>
